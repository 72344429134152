import React, { useState, useEffect } from "react";

import AdEngageLayout from "components/layout";
import {  CURRENT_TOP_EVENT_KEY, TOP_EVENTS } from "utils/constants";

import classNames from "classnames";
import * as styles from "components/category_overview/styles.module.scss";
import * as colors from "styles/colors.module.scss";
import AdEngageButton from "components/ad_engage_button";
import { Link } from "gatsby";
import TopEventByCategoryOverview from "components/category_overview/TopEventByCategoryOverview";

declare global {
  interface Window {
    lintrk?: any;
    gtag?: any;
  }
}

const AmazonTnfSpanishPublicAdEngage = () => {
  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-12429278/visit/nflpa0+unique'
      });
    }
  });

  const pageHeader = () => (
    <div className={classNames(styles.pageHeader)}>
      <div className={classNames(styles.headerBanner)}>
        <div className={classNames(styles.headerTitle)}>
          <h2 className={classNames(colors.white, styles.headerBold, "edo-h2")}>Amazon + NFL Thursday Night Football</h2>
        </div>
        <div className={classNames("edo-tagline", colors.white, styles.description, styles.headerDescription)}>
          <p className={classNames(styles.headerBold)}>
            A Prime environment for brand TV performance
          </p>
        </div>
        <AdEngageButton label="Request Demo" gotoLink="/contact-us" href={"/contact-us"}
                        extraClassNames={classNames(styles.headerButton, styles.demoButton)}/>
        {/* <span className={classNames(styles.headerButton, colors.white)}>
            <Link to="/advertisers/ad-engage-convergent" className={styles.viewFeatures}>View Features</Link>
          </span> */}
        {/* <AdEngageButton label="View Features" gotoLink="/advertisers/ad-engage-convergent" href={"/contact-us"}
                        extraClassNames={classNames(styles.featuresButton, styles.demoButton)}/> */}
      </div>
    </div>
  );

  const pageFooter = () => (
    <div className={classNames(styles.pageFooter)}>
      <div className={"flex-col"}>
        <div className={classNames(styles.footerText, styles.nflFooter)}>
          <p style={{ marginBottom: 0 }}>Predict and understand the impact of your big bets before the Monday morning quarterbacking begins. When your ad is on the line, access to real-time customer engagement is the only thing that counts.</p>
          <b>Contact one of our industry specialists to learn more.</b>
        </div>
        <div className={styles.nflDemoButton}>
          <AdEngageButton label="Reach Out" gotoLink="/contact-us" href={"/contact-us"}
                          extraClassNames={classNames(styles.headerButton, styles.demoButton)}/>
        </div>
      </div>
    </div>
  );

  return (
    <AdEngageLayout pageTitle={TOP_EVENTS.nfl.title} >
      <TopEventByCategoryOverview
        pageHeader={pageHeader()}
        pageFooter={pageFooter()}
        amazonTnfSpanish
        headerTitle="Amazon Thursday Night Football Creatives"
      />
    </AdEngageLayout>
  )
};

export default AmazonTnfSpanishPublicAdEngage;
